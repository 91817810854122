import { createRouter, createWebHistory } from "vue-router";
import Main from "../views/Main.vue";

const routes = [
  {
    path: "/",
    name: "OpenStreetPAY",
    component: Main,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(/* webpackChunkName: "challenge" */ "../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () =>
      import(/* webpackChunkName: "idee" */ "../views/Datenschutz.vue"),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: () =>
      import(/* webpackChunkName: "kontakt" */ "../views/Kontakt.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
        try {
          setTimeout(() => {
            const elem = document.getElementById(to.hash.replace("#",""));
            elem.scrollIntoView();
          }, 10);
        //eslint-disable-next-line no-empty
        } catch(_) {}
    }
    return { x: 0, y: 0 }
},
});

export default router;
